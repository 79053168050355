import { scmpPlus } from "@product/scmp-sdk";
import enc from "crypto-js/enc-hex";
import SHA256 from "crypto-js/sha256";
import { addMonths, endOfDay, startOfDay } from "date-fns";
import type { NextPageContext, PageProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { graphql, usePreloadedQuery } from "react-relay";
import type { RelayComponent } from "relay-nextjs";
import { withRelay } from "relay-nextjs";

import type { AppBarVariant } from "scmp-app/components/app-bar/types";
import { DefaultCustomContents } from "scmp-app/components/article/article-render/consts";
import { FallbackLoading } from "scmp-app/components/fallback-loading";
import { SectionContextProvider } from "scmp-app/components/section/contexts";
import { DefaultSection } from "scmp-app/components/section/default-section";
import { getIncludeSectionFeatureMap } from "scmp-app/components/section/default-section/helpers";
import { SectionPostMag } from "scmp-app/components/section/section-post-magazine";
import { SectionSeo } from "scmp-app/components/section/section-seo";
import { SectionStyle } from "scmp-app/components/section/section-style";
import { SectionStyleSeo } from "scmp-app/components/section/section-style/section-style-seo";
import { data as appData, article as articleData } from "scmp-app/data";
import { section as sectionData } from "scmp-app/data/section";
import { useTrackCurrentItem } from "scmp-app/lib/current-item/hooks";
import { createClientEnvironment } from "scmp-app/lib/relay/environment.client";
import { parseQueryString } from "scmp-app/lib/utils";
import { Variant as SectionVariant, Variant } from "scmp-app/pages/section/enums";
import type { sectionPageQuery } from "scmp-app/queries/__generated__/sectionPageQuery.graphql";

import { getSectionNameSlug } from "./helpers";

const query = graphql`
  query sectionPageQuery(
    $applicationId: String!
    $articlesQueueName: String!
    $commentHarrySectionIds: [String]
    $commentQueueName: String!
    $commentQueueLimit: Int!
    $customContents: [CustomContentInput]
    $dailyPulseTypeUuId: String!
    $entityId: String!
    $highlightQueueName: String!
    $isStyleSection: Boolean!
    $isPostMagazineSection: Boolean!
    $isSkipDefaultSectionQuery: Boolean!
    $mostPopularQueueName: String!
    $multimediaQueueName: String!
    $plusNewsAgendaEndDate: Timestamp!
    $plusNewsAgendaStartDate: Timestamp!
    $plusNewsAgendaTypes: [String]!
    $scmpPlusPaywallTypeIds: [String]
    $sponsorQueueName: String!
    $sectionSpotlightQueueName: String!
    $trendingTopicsQueueName: String!
    # Include widget feature
    $includeAsia: Boolean!
    $includeChinaScienceDiscoveryModule: Boolean!
    $includeComment: Boolean!
    $includeMostPopular: Boolean!
    $includeMultimedia: Boolean!
    $includePlus: Boolean!
    $includeThisWeekInAsia: Boolean!
    $includeTrendingTopics: Boolean!
  ) {
    section(filter: { entityId: $entityId }) {
      entityId
      name
      ...hooksTrackCurrentItemBase @arguments(customContents: $customContents)
      ...contextsSectionContextProviderSection
      ...sectionSeoSection
    }
    ...defaultSectionQuery
      @arguments(
        applicationId: $applicationId
        articlesQueueName: $articlesQueueName
        commentHarrySectionIds: $commentHarrySectionIds
        commentQueueName: $commentQueueName
        commentQueueLimit: $commentQueueLimit
        dailyPulseTypeUuId: $dailyPulseTypeUuId
        entityId: $entityId
        highlightQueueName: $highlightQueueName
        mostPopularQueueName: $mostPopularQueueName
        multimediaQueueName: $multimediaQueueName
        plusNewsAgendaEndDate: $plusNewsAgendaEndDate
        plusNewsAgendaStartDate: $plusNewsAgendaStartDate
        plusNewsAgendaTypes: $plusNewsAgendaTypes
        scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
        trendingTopicsQueueName: $trendingTopicsQueueName
        sectionSpotlightQueueName: $sectionSpotlightQueueName
        includeAsia: $includeAsia
        includeChinaScienceDiscoveryModule: $includeChinaScienceDiscoveryModule
        includeComment: $includeComment
        includeMostPopular: $includeMostPopular
        includeMultimedia: $includeMultimedia
        includePlus: $includePlus
        includeThisWeekInAsia: $includeThisWeekInAsia
        includeTrendingTopics: $includeTrendingTopics
      )
      @skip(if: $isSkipDefaultSectionQuery)

    ...sectionStyleContentQuery
      @arguments(articlesQueueName: $articlesQueueName)
      @include(if: $isStyleSection)
    ...sectionPostMagazineQuery
      @arguments(
        entityId: $entityId
        articlesQueueName: $articlesQueueName
        sponsorQueueName: $sponsorQueueName
      )
      @include(if: $isPostMagazineSection)
  }
`;

const SectionPage: RelayComponent<PageProps, sectionPageQuery> = ({
  preloadedQuery,
  randomSeed,
}) => {
  const { query: routeQuery } = useRouter();
  const data = usePreloadedQuery(query, preloadedQuery);
  const { trackCurrentItem } = useTrackCurrentItem();

  useEffect(() => {
    trackCurrentItem(data.section);
  }, [data.section, trackCurrentItem]);

  const handleRenderSection = () => {
    switch (routeQuery.sectionVariant) {
      case Variant.Style:
        return <SectionStyle reference={data} />;
      case Variant.PostMagazine:
        return <SectionPostMag randomSeed={randomSeed} reference={data} />;
      default:
        return <DefaultSection reference={data} />;
    }
  };

  const handleRenderPageSeo = () => {
    switch (routeQuery.sectionVariant) {
      case Variant.Style:
        return <SectionStyleSeo reference={data.section} />;
      default:
        return <SectionSeo reference={data.section} twitter={{ cardType: "summary" }} />;
    }
  };

  // TODO: handle head meta seo
  return (
    <>
      {handleRenderPageSeo()}
      <SectionContextProvider reference={data.section}>
        {handleRenderSection()}
      </SectionContextProvider>
    </>
  );
};

SectionPage.displayName = "SectionPage";

const getPageProps = (context: NextPageContext) => {
  const isSectionStyle = context.query.sectionVariant === SectionVariant.Style;
  const isPostMagazine = context.query.sectionVariant === SectionVariant.PostMagazine;
  const pageProps: PageProps = {
    appBarConfiguration: {
      adSlotVariants: {
        large: {
          height: isSectionStyle ? 360 : 250,
          padding: isSectionStyle ? 24 : 20,
        },
        small: {
          height: 100,
          padding: 10,
        },
      },
      hasDesktopAd: !isPostMagazine,
      hasMobileAd: !isPostMagazine,
      variant: context.query.appBarVariant as AppBarVariant,
    },
    contentConfiguration: {
      responsivePadding: {
        desktopUp: 12,
        tabletUp: 0,
      },
    },
    headerConfiguration: {
      responsiveFeatures: isSectionStyle
        ? {
            desktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "my-news", "dashboard", "hamburger-menu"],
              },
            },
            homeDesktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            largeDesktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            mediumDesktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            mobileUp: {
              default: { left: ["logo-link-style"], right: ["dashboard", "hamburger-menu"] },
              nonSubscriberSwapped: {
                left: ["logo-link-style", "text-link-style"],
                right: ["dashboard", "hamburger-menu"],
              },
              swapped: {
                left: ["logo-link-style", "text-link-style"],
                right: ["dashboard", "hamburger-menu"],
              },
            },
            tabletUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "my-news", "dashboard", "hamburger-menu"],
              },
            },
          }
        : undefined,
    },
    pageConfiguration: {
      responsivePadding: {
        tabletUp: 24,
      },
    },
  };

  return pageProps;
};

const generateRandomSeed = () => SHA256(new Date().toString()).toString(enc);

export default withRelay(SectionPage, query, {
  clientSideProps: context => ({ ...getPageProps(context), randomSeed: generateRandomSeed() }),
  createClientEnvironment: () => createClientEnvironment(),
  createServerEnvironment: async context => {
    const { createServerEnvironment } = await import("scmp-app/lib/relay/environment.server");
    return createServerEnvironment(context.req?.cookies);
  },
  fallback: <FallbackLoading />,
  serverSideProps: context =>
    Promise.resolve({ ...getPageProps(context), randomSeed: generateRandomSeed() }),
  variablesFromContext(context) {
    const entityId = parseQueryString(context.query.entityId) ?? "";
    const sectionNameSlug = getSectionNameSlug(parseQueryString(context.query.name) ?? "");

    const getCommentQueueLimit = () => {
      switch (entityId) {
        case sectionData.sport.entityId:
          return 4;
        case sectionData.asia.entityId:
        case sectionData.thisWeekInAsia.entityId:
          return 5;
        default:
          return 6;
      }
    };

    const queueNames = {
      articlesQueueName: `section_top_${entityId}`,
      commentQueueLimit: getCommentQueueLimit(),
      commentQueueName: `section_top_opinion_${entityId}`,
      highlightQueueName: "scmp_plus_home_news",
      mostPopularQueueName: `scmp_trending_section_scmp_trending_section_${sectionNameSlug}_last_7_days`,
      multimediaQueueName: `section_top_visual_stories_${entityId}`,
      sectionSpotlightQueueName: `section_top_scmp_signatures_${entityId}`,
      sponsorQueueName: `section_brand_post_${entityId}`,
      trendingTopicsQueueName: `related_topics_${entityId}`,
    };
    const startDate = startOfDay(new Date()).getTime();
    const endDate = addMonths(endOfDay(new Date()), 1).getTime();

    return {
      ...queueNames,
      ...getIncludeSectionFeatureMap(entityId),
      applicationId: appData.application.scmp.entityId,
      commentHarrySectionIds: [sectionData.comment.harrysView.entityUuid],
      customContents: DefaultCustomContents,
      dailyPulseTypeUuId: articleData.types.dailyPulseBriefing.entityUuid,
      entityId,
      isPostMagazineSection: context.query.sectionVariant === SectionVariant.PostMagazine,
      isSkipDefaultSectionQuery:
        context.query.sectionVariant === SectionVariant.Style ||
        context.query.sectionVariant === SectionVariant.PostMagazine,
      isStyleSection: context.query.sectionVariant === SectionVariant.Style,
      plusNewsAgendaEndDate: endDate,
      plusNewsAgendaStartDate: startDate,
      plusNewsAgendaTypes: [scmpPlus.topicType.newsAgenda],
      scmpPlusPaywallTypeIds: [scmpPlus?.article?.paywallTypeId],
    };
  },
});
