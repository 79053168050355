import { Fragment, type ReactNode } from "react";
import { graphql, readInlineData } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { Comment } from "scmp-app/components/comment";
import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { MostPopular } from "scmp-app/components/most-popular";
import { Multimedia } from "scmp-app/components/multimedia";
import type { PlusWidgetsSampleContentType } from "scmp-app/components/plus/plus-widget";
import { PostMagazineDiscoveryWidget } from "scmp-app/components/post-magazine/discovery-widget";
import { ChinaScienceDiscoveryModule } from "scmp-app/components/rhs-module/china-science-discovery-module";
import { AiArticleWidget } from "scmp-app/components/section/ai-article-widget";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { TrendingTopic } from "scmp-app/components/trending-topic";
import { section as sectionData } from "scmp-app/data/section";
import type { hooksSectionWidgetQuery$key } from "scmp-app/queries/__generated__/hooksSectionWidgetQuery.graphql";

import { getDefaultSectionAdsConfigs } from "./ads-config";
import { AsiaWidget } from "./asia-widget";
import { getSectionWidgets } from "./helpers";
import {
  AsiaContainer,
  CommentContainer,
  FifthAds,
  FirstAds,
  FourthAds,
  MostPopularContainer,
  MultimediaContainer,
  RHSModule1,
  RHSModule2,
  RHSModule3,
  RHSModule4,
  RHSModule5,
  RHSModule6,
  SecondAds,
  SixthAds,
  StyledPlusWidget,
  ThirdAds,
  TrendingTopicContainer,
} from "./styles";
import { ThisWeekInAsiaWidget } from "./this-week-in-asia-widget";
import type { SectionWidget } from "./types";

export const useRenderAdsAndSectionWidgets = (
  reference: hooksSectionWidgetQuery$key,
  LHSModuleLength: number,
) => {
  const { advertisement: baseAdvertisement } = useSectionContext();
  const data = readInlineData(
    graphql`
      fragment hooksSectionWidgetQuery on Query
      @inline
      @argumentDefinitions(
        applicationId: { type: "String!" }
        commentHarrySectionIds: { type: "[String]" }
        commentQueueName: { type: "String!" }
        commentQueueLimit: { type: "Int!" }
        dailyPulseTypeUuId: { type: "String!" }
        highlightQueueName: { type: "String!" }
        mostPopularQueueName: { type: "String!" }
        multimediaQueueName: { type: "String!" }
        plusNewsAgendaEndDate: { type: "Timestamp!" }
        plusNewsAgendaStartDate: { type: "Timestamp!" }
        plusNewsAgendaTypes: { type: "[String]!" }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
        sectionId: { type: "String!" }
        trendingTopicsQueueName: { type: "String!" }
        # Include widget features
        includeAsia: { type: "Boolean!" }
        includeChinaScienceDiscoveryModule: { type: "Boolean!" }
        includeComment: { type: "Boolean!" }
        includeMostPopular: { type: "Boolean!" }
        includeMultimedia: { type: "Boolean!" }
        includePlus: { type: "Boolean!" }
        includeThisWeekInAsia: { type: "Boolean!" }
        includeTrendingTopics: { type: "Boolean!" }
      ) {
        section: section(filter: { entityId: $sectionId }) {
          entityId
          advertZone(version: 2)
        }
        ...asiaWidgetQueueQuery @include(if: $includeAsia)
        ...thisWeekInAsiaWidgetQueue @include(if: $includeThisWeekInAsia)
        ...commentQueueQuery
          @include(if: $includeComment)
          @arguments(
            commentHarrySectionIds: $commentHarrySectionIds
            commentLimit: $commentQueueLimit
            commentQueueName: $commentQueueName
            scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
            withHarrysView: false
          )
        ...discoveryWidgetPostMagazineQueueQuery
        ...mostPopularQueueQuery
          @include(if: $includeMostPopular)
          @arguments(mostPopularQueueName: $mostPopularQueueName)
        ...multimediaQuery
          @include(if: $includeMultimedia)
          @arguments(multimediaQueueName: $multimediaQueueName)
        ...trendingTopicTopic
          @include(if: $includeTrendingTopics)
          @arguments(trendingTopicsQueueName: $trendingTopicsQueueName)
        ...plusWidgetQuery
          @include(if: $includePlus)
          @arguments(
            applicationId: $applicationId
            dailyPulseTypeUuId: $dailyPulseTypeUuId
            highlightQueueName: $highlightQueueName
            plusNewsAgendaEndDate: $plusNewsAgendaEndDate
            plusNewsAgendaStartDate: $plusNewsAgendaStartDate
            plusNewsAgendaTypes: $plusNewsAgendaTypes
          )
        ...chinaScienceDiscoveryModuleQueueQuery @include(if: $includeChinaScienceDiscoveryModule)
      }
    `,
    reference,
  );

  const discoveryModuleSampleContentNameMapping: Record<string, PlusWidgetsSampleContentType> = {
    [sectionData.hongKong.entityId]: sectionData.hongKong.aiEngineName,
    [sectionData.china.entityId]: sectionData.china.aiEngineName,
    [sectionData.economy.entityId]: sectionData.economy.aiEngineName,
    [sectionData.business.entityId]: sectionData.business.aiEngineName,
  };

  const renderAsia = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "asia",
        pgtype: "section",
      }}
    >
      <AsiaContainer>
        <AsiaWidget reference={data} />
      </AsiaContainer>
    </BaseLinkContextProvider>
  );

  const renderThisWeekInAsia = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "asia",
        pgtype: "section",
      }}
    >
      <AsiaContainer>
        <ThisWeekInAsiaWidget reference={data} />
      </AsiaContainer>
    </BaseLinkContextProvider>
  );

  const renderComment = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "opinion",
        pgtype: "section",
      }}
    >
      <CommentContainer>
        <Comment reference={data} withMoreCommentButton={false} />
      </CommentContainer>
    </BaseLinkContextProvider>
  );
  const renderMultimedia = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "multimedia",
        pgtype: "section",
      }}
    >
      <MultimediaContainer>
        <Multimedia reference={data} />
      </MultimediaContainer>
    </BaseLinkContextProvider>
  );
  const renderMostPopular = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "most_popular",
        pgtype: "section",
      }}
    >
      <MostPopularContainer>
        <MostPopular reference={data} />
      </MostPopularContainer>
    </BaseLinkContextProvider>
  );
  const renderTrendingTopics = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "trending_topics",
        pgtype: "section",
      }}
    >
      <TrendingTopicContainer>
        <TrendingTopic reference={data} />
      </TrendingTopicContainer>
    </BaseLinkContextProvider>
  );
  const renderRecommendedForYou = () => {
    let sectionIds = baseAdvertisement.targeting.scsid ?? [];
    if (baseAdvertisement.targeting.scsid?.includes(sectionData.olympic.entityId)) {
      sectionIds = [sectionData.sport.entityId];
    }
    return <AiArticleWidget sectionIds={sectionIds} />;
  };

  const renderPlusWidget = () => (
    <StyledPlusWidget
      pageType="section"
      reference={data}
      sampleContent={discoveryModuleSampleContentNameMapping[data.section.entityId] ?? null}
    />
  );

  const renderPostMagazine = () => <PostMagazineDiscoveryWidget reference={data} />;
  const renderChinaScienceDiscoveryModule = () => <ChinaScienceDiscoveryModule reference={data} />;

  const rhsModuleComponents = [
    RHSModule1,
    RHSModule2,
    RHSModule3,
    RHSModule4,
    RHSModule5,
    RHSModule6,
  ];

  const sectionWidgets = getSectionWidgets(data.section.entityId);
  const sectionWidgetComponentMap: Record<SectionWidget, () => ReactNode> = {
    asia: renderAsia,
    "china-science-discovery": renderChinaScienceDiscoveryModule,
    comment: renderComment,
    "most-popular": renderMostPopular,
    multimedia: renderMultimedia,
    plus: renderPlusWidget,
    "post-magazine": renderPostMagazine,
    "recommended-for-you": renderRecommendedForYou,
    "this-week-in-asia": renderThisWeekInAsia,
    "trending-topics": renderTrendingTopics,
  };
  const rhsModule = sectionWidgets.map(widget => sectionWidgetComponentMap[widget]);

  const adSlotComponents = [SecondAds, ThirdAds, FourthAds, FifthAds, SixthAds];
  const adConfigs = getDefaultSectionAdsConfigs(
    data.section.entityId,
    data?.section?.advertZone ?? undefined,
  );
  const [[firstAdConfigDesktop, firstAdConfigMobile], ...restSdConfigs] = adConfigs;

  let updatedRHSModule = rhsModule;
  let updatedDesktopAdsConfig = restSdConfigs;
  const restSdConfigsLength = restSdConfigs.length;

  if (LHSModuleLength / 1.8 < rhsModule.length) {
    const removeCount = rhsModule.length - Math.floor(LHSModuleLength / 1.8);
    updatedRHSModule = rhsModule.slice(0, -removeCount);

    switch (data.section.entityId) {
      case sectionData.tech.entityId:
      case sectionData.comment.entityId:
      case sectionData.sport.entityId:
      case sectionData.olympic.entityId:
      case sectionData.kPop.entityId:
        updatedDesktopAdsConfig = restSdConfigs.slice(0, restSdConfigsLength - 1);
        break;
      default:
        updatedDesktopAdsConfig = restSdConfigs.slice(0, restSdConfigsLength - 2);
        break;
    }
  }

  const render = () => (
    <>
      <FirstAds>
        {firstAdConfigDesktop && <AdSlot {...firstAdConfigDesktop} />}
        <AdSlot {...firstAdConfigMobile} />
      </FirstAds>
      {/* temp using HK section layout for all */}
      {/* later should change back to 'data.section.entityId' */}
      {updatedRHSModule.map((rhsModule, index) => {
        const Wrapper = rhsModuleComponents[index];
        const AdSlotComponent = adSlotComponents[index];
        const [desktopAdsConfig] = updatedDesktopAdsConfig[index] ?? [];
        const [_, mobileAdsConfig] = restSdConfigs[index] ?? [];
        return (
          <Fragment key={index}>
            <Wrapper>{rhsModule()}</Wrapper>
            {(desktopAdsConfig || mobileAdsConfig) && (
              <AdSlotComponent>
                {desktopAdsConfig && <AdSlot {...desktopAdsConfig} />}
                {mobileAdsConfig && <AdSlot {...mobileAdsConfig} />}
              </AdSlotComponent>
            )}
          </Fragment>
        );
      })}
    </>
  );

  return { render };
};
