/**
 * @generated SignedSource<<55ccf9efd5020b9deda95bc96168bd27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksSectionWidgetQuery$data = {
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"asiaWidgetQueueQuery" | "chinaScienceDiscoveryModuleQueueQuery" | "commentQueueQuery" | "discoveryWidgetPostMagazineQueueQuery" | "mostPopularQueueQuery" | "multimediaQuery" | "plusWidgetQuery" | "thisWeekInAsiaWidgetQueue" | "trendingTopicTopic">;
  readonly " $fragmentType": "hooksSectionWidgetQuery";
};
export type hooksSectionWidgetQuery$key = {
  readonly " $data"?: hooksSectionWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSectionWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksSectionWidgetQuery"
};

(node as any).hash = "510d0c94d83e4a30e6f4e3b82496fb56";

export default node;
