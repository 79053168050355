import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";

import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  position: relative;
`;

export const ContentContainer = styled.div`
  position: absolute;
  inset-block-end: 0;

  display: grid;
  gap: 16px;

  inline-size: 100%;
  padding-block-end: 40px;
  padding-inline: 26px 46px;

  ${props => props.theme.breakpoints.up("tablet")} {
    max-inline-size: 728px;
    padding-block-end: 60px;
    padding-inline: 32px 0;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 840px;
    padding-block-end: 80px;
    padding-inline-start: 40px;
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  font-family: ${fontIndivisibleVariable};
  line-height: 110%;
  letter-spacing: -0.72px;
  font-variation-settings: "wght" 500;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 32px;
    letter-spacing: -0.96px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-size: 48px;
    letter-spacing: -1.68px;
  }
`;

export const Headline = styled.div`
  ${Flag} {
    color: #111111;
  }
`;

export const StyledCoverEntityLink = styled(EntityLink)`
  aspect-ratio: 2/3;

  display: block;

  ${props => props.theme.breakpoints.up("tablet")} {
    aspect-ratio: 3/2;
  }
`;

export const CoverImage = styled.div`
  position: relative;
  aspect-ratio: 2/3;

  inline-size: 100%;
  block-size: 100%;

  ${props => props.theme.breakpoints.up("tablet")} {
    aspect-ratio: 3/2;
  }
`;

export const Topic = styled.div`
  > a {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    font-family: ${fontIndivisibleVariable};
    line-height: 110%;
    letter-spacing: -0.48px;
    text-transform: uppercase;
    font-variation-settings: "wght" 500;

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 20px;
      letter-spacing: -0.6px;
    }

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 24px;
      letter-spacing: -0.72px;
    }
  }
`;
