import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";

import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-inline-size: 373px;
  ${props => props.theme.breakpoints.up("tablet")} {
    justify-content: flex-start;
  }
`;

export const Topic = styled.div`
  > a {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    font-family: ${fontIndivisibleVariable};
    letter-spacing: -0.014px;
    text-align: center;
    text-transform: uppercase;

    font-variation-settings: "wght" 500;

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 16px;
      letter-spacing: -0.016px;
    }
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  margin-block-end: 16px;

  color: #111111;
  font-weight: 500;
  font-size: 24px;
  font-family: ${fontIndivisibleVariable};
  line-height: 110%;
  letter-spacing: -0.72px;
  text-align: center;
  font-variation-settings: "wght" 500;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-size: 32px;
    letter-spacing: -0.96px;
  }
`;

export const Headline = styled.div`
  font-size: 24px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-size: 28px;
  }
  ${Flag} {
    color: #111111;
  }
`;

export const StyledCoverEntityLink = styled(EntityLink)`
  aspect-ratio: 1/1;

  inline-size: 100%;
  margin-block-end: 24px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-end: 30px;
  }
`;

export const CoverImage = styled.div`
  position: relative;
  aspect-ratio: 1/1;

  inline-size: 100%;
  block-size: 100%;
`;
