import styled from "@emotion/styled";
import { fontBodoni, fontIndivisibleVariable } from "@product/scmp-sdk";

import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div``;

export const StyledEntityLink = styled(EntityLink)`
  position: relative;

  display: block;

  color: #000000;
`;

export const Header = styled.div`
  margin-block-end: 8px;
`;

export const ActionBar = styled.div`
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 20px;
  }
  time {
    color: #999999;
    font-size: 12px;
  }
`;

export const CoverImage = styled.div`
  aspect-ratio: 1;

  inline-size: 100%;
  block-size: 100%;
  ${props => props.theme.breakpoints.up("tablet")} {
    aspect-ratio: 5/2;
  }
`;

export const StyledCoverEntityLink = styled(EntityLink)``;

export const Topic = styled.div`
  grid-area: topic;
  > a {
    font-weight: 500;
    font-size: 14px;
    font-family: ${fontIndivisibleVariable};
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.014px;
    text-transform: uppercase;
    font-variation-settings: "wght" 500;
    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 18px;
      letter-spacing: -0.022px;
    }
  }
`;

export const StyledContentEntityLink = styled(EntityLink)`
  grid-area: content;

  display: flex;
  flex-direction: column;
  gap: 20px;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex-direction: row;
    gap: 32px;
  }
`;

export const Headline = styled.div`
  font-weight: 400;
  font-size: 32px;
  font-family: ${fontBodoni};
  font-style: normal;
  line-height: 110%;
  letter-spacing: -0.96px;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex: 1;

    font-size: 48px;
    letter-spacing: -1.68px;
  }

  ${Flag} {
    color: #111111;
  }
`;

export const Summary = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontIndivisibleVariable};
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.28px;
  font-variant-numeric: lining-nums proportional-nums;
  font-variation-settings: "wght" 300;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.32px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 280px;

    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.36px;
    font-variation-settings: "wght" 400;
  }
`;

export const Divider = styled.div`
  grid-area: divider;

  display: none;

  inline-size: 1px;
  block-size: 100%;

  background-color: #777777;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;
  }
`;

export const AdSlot = styled.div`
  grid-area: ad-slot;
`;

export const ContentContainer = styled.div`
  grid-area: content-container;

  display: grid;
  grid:
    "topic" min-content
    "." 8px
    "content" min-content
    / auto;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    grid:
      "topic topic" min-content
      "content content" min-content
      / auto;
    gap: 10px 32px;

    padding-inline-end: 40px;
    border-inline-end: 1px solid #777777;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid:
    "content-container" min-content
    "." 40px
    "ad-slot" min-content
    / auto;

  margin-block-start: 24px;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "content-container ad-slot" min-content
      / auto 300px;
    gap: 0 40px;

    margin-block-start: 40px;
  }
`;
