import CreateSeedRandom from "seed-random";

import type { onePostMagSectionTopWidgetSponsorQueueItemsEdge$data } from "scmp-app/queries/__generated__/onePostMagSectionTopWidgetSponsorQueueItemsEdge.graphql";

export const usePickSubSectionArticles = (
  seed: string,
  slotArticles: onePostMagSectionTopWidgetSponsorQueueItemsEdge$data | null | undefined,
  isDisplayArticle: boolean,
) => {
  if (!isDisplayArticle) {
    return null;
  }

  const random = CreateSeedRandom(seed);
  const articles = slotArticles ?? [];
  const slotPicked = Math.floor(random() * articles.length);

  return articles.length > slotPicked ? articles[slotPicked] : articles.at(-1);
};
